/* eslint-disable */
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { receptionDeskVars } from '../2023config/2023Vars';



// function CreatePerson({ getParticipants, setCanUseCacheData, handlePersonChange, setCurrentPerson, participants, setParticipants, user, togglePersonCardVisibility, isPersonCardVisible }) {
//   const navigate = useNavigate();
//   const [addedID, setAddedID] = useState(null)
//   const [errorCellphoneFormat, setErrorErrorCellphoneFormat] = useState('');

//   const [editPerson, setEditPerson] = useState({
//     id: '',
//     first_name: '',
//     last_name: '',
//     cellphone: '',
//     title: '',
//     department_txt: '',
//     misc: '',
//   });


//   const isFormValid = editPerson.first_name !== ''
//     && editPerson.last_name !== ''
//     && editPerson.cellphone !== ''
//     && errorCellphoneFormat === ''
//     && editPerson.title !== ''
//     && editPerson.department_txt !== '';




//   const isPhoneAlreadyInUse = participants.find(obj => parseInt(obj.id) !== parseInt(editPerson.id) && obj.cellphone === editPerson.cellphone);

//   const handlePhoneNumberChange = (e) => {
//     const inputValue = e.target.value;
//     const isValid = /^[0-9+]*$/.test(inputValue);

//     if (isValid) {
//       setEditPerson({ ...editPerson, cellphone: inputValue });
//       setErrorErrorCellphoneFormat('');
//     } else {
//       setEditPerson({ ...editPerson, cellphone: inputValue });
//       setErrorErrorCellphoneFormat('Please enter a valid phone number.  ');
//     }
//   };




//   // moved this from the api call below
//   useEffect(() => {
//     if (addedID) {
//       getParticipants();

//       togglePersonCardVisibility()
//       setCurrentPerson(addedID)
//       handlePersonChange(addedID)

//       setCanUseCacheData(true)
//     }
//   }, [addedID, participants]);


//   const handleEditPerson = async (e) => {
//     e.preventDefault();

//     if (!isFormValid) {
//       return;
//     }

//     const timestamp = new Date().toISOString();
//     const updatedbyLog = `[${user.first} ${user.last} (${timestamp})] ADD PERSON`;

//     // setCanUseCacheData(false)

//     const config = {
//       method: "post",
//       url: `${receptionDeskVars.setup.apiUrl}/addAndUpdatePart`,
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//       },
//       data: { ...editPerson, misc: updatedbyLog },
//     };
//     setCanUseCacheData(false)

//     try {
//       const response = await axios(config);

//       if (response.status === 200) {
//         console.log('API Response:', config, response.data);
//         const newID = response.data.updatedPart;

//         const editedUpdatePerson = { ...editPerson, id: newID, territory_id: `999` };
//         console.log('...................API Response:editedUpdatePerson---------', editedUpdatePerson);
//         const editedParticipants = [...participants, editedUpdatePerson];
//         console.log('...............API Response:editedParticipants---------', editedParticipants);
//         // setParticipants([...participants, editPerson]);
//         setParticipants(editedParticipants);
//         // setCurrentPerson(newID)
//         /* togglePersonCardVisibility()*/
//         // navigate(`/home/messages/${newID}`);

//         setAddedID(newID)

//         /*setCurrentPerson(newID)
//         handlePersonChange(newID)*/

//         // setCanUseCacheData(true)

//       } else {
//         // Handle API error here
//         console.error('API Error:', response.statusText);

//         // setCanUseCacheData(true)

//       }
//     } catch (error) {
//       console.error('API Request Failed:', error);
//     }

//   }




//   return (
//     <div className={`person-details-card ${isPersonCardVisible ? 'show-person-card' : 'hide-person-card'}`}>
//       <div className="person-details-top-row" onClick={() => {
//         navigate(`/home/search/reset`);

//       }}>
//         <div className="person-details-top-row-content">
//           &nbsp; <i className={receptionDeskVars.faicons.addPersonHeaderIcon}></i> {receptionDeskVars.text.misc.addPersonHeaderPrefix}  &nbsp; {editPerson.first_name} {editPerson.last_name}
//         </div>
//         CANCEL <i className={`fa ${isPersonCardVisible ? 'fa-regular fa-circle-xmark' : 'fa-regular fa-circle-xmark'}`} aria-hidden="true"></i>
//       </div>
//       <div className="person-details-middle-row">
//         <div className="person-details-box-left">
//           <form className='edit-card' noValidate>
//             <div className="form-group">
//               {/* <label htmlFor="first_name">FIRST NAME:</label> */}
//               <input
//                 type="text"
//                 className="user-input-field"
//                 id="first_name"
//                 value={editPerson.first_name}
//                 onChange={(e) => setEditPerson({ ...editPerson, first_name: e.target.value })}
//                 placeholder={`First Name`}
//                 autoComplete="off"
//                 spellCheck={false}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               {/* <label htmlFor="last_name">LAST NAME:</label> */}
//               <input
//                 type="text"
//                 className="user-input-field"
//                 id="last_name"
//                 value={editPerson.last_name}
//                 onChange={(e) => setEditPerson({ ...editPerson, last_name: e.target.value })}
//                 placeholder={`Last Name`}
//                 autoComplete="off"
//                 spellCheck={false}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               {/* <label htmlFor="cellphone">CELL PHONE: {isPhoneAlreadyInUse ? <span className="error-message"> &nbsp; Sorry, this cellphone number already in use</span> : ''}</label> */}
//               <label htmlFor="cellphone"><b>ENTER VALID CELLPHONE - NO SPACES! (e.g. 9495551212 or +4445556668886)</b></label>
//               <input
//                 type="text"
//                 className="user-input-field"
//                 id="cellphone"
//                 value={editPerson.cellphone}
//                 // onChange={(e) => setEditPerson({ ...editPerson, cellphone: e.target.value })}
//                 onChange={handlePhoneNumberChange}
//                 placeholder={`Cell Phone`}
//                 autoComplete="off"
//                 spellCheck={false}
//                 required
//               />
//               <label htmlFor="cellphone"> <span className="error-message">{errorCellphoneFormat}</span>  {isPhoneAlreadyInUse ? <span className="error-message">Sorry, this cellphone number already in use</span> : ''}</label>
//             </div>
//           </form>
//         </div>
//         <div className="person-details-box-right">
//           <form className='edit-card' noValidate>
//             <div className="form-group">
//               {/* <label htmlFor="title">TITLE:</label> */}
//               <input
//                 type="text"
//                 className="user-input-field"
//                 id="title"
//                 value={editPerson.title}
//                 onChange={(e) => setEditPerson({ ...editPerson, title: e.target.value })}
//                 placeholder={`Title`}
//                 autoComplete="off"
//                 spellCheck={false}
//                 required
//               />
//             </div>
//             <div className="form-group">
//               {/* <label htmlFor="department_txt">DEPARTMENT:</label> */}
//               <input
//                 type="text"
//                 className="user-input-field"
//                 id="department_txt"
//                 value={editPerson.department_txt}
//                 onChange={(e) => setEditPerson({ ...editPerson, department_txt: e.target.value })}
//                 placeholder={`Deparment`}
//                 autoComplete="off"
//                 spellCheck={false}
//                 required
//               />
//             </div>
//             <br />
//             <button
//               disabled={!isFormValid || isPhoneAlreadyInUse}
//               type="button"
//               className={`btn btn-${!isFormValid ? 'primary' : 'primary'}`}
//               onClick={handleEditPerson}>
//               {/* {`${receptionDeskVars.buttons.signIn}`} */}
//               ADD PERSON
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// }




function CreatePerson({ getParticipants, setCanUseCacheData, handlePersonChange, setCurrentPerson, participants, setParticipants, user, togglePersonCardVisibility, isPersonCardVisible }) {
  const navigate = useNavigate();
  const [addedID, setAddedID] = useState(null)
  const [personWasUpdated, setPersonWasUpdated] = useState(false)
  const [errorCellphoneFormat, setErrorErrorCellphoneFormat] = useState('');

  const [editPerson, setEditPerson] = useState({
    id: '',
    first_name: '',
    last_name: '',
    cellphone: '',
    title: '',
    department_txt: '',
    misc: '',
  });


  const isFormValid = editPerson.first_name !== ''
    && editPerson.last_name !== ''
    && editPerson.cellphone !== ''
    && errorCellphoneFormat === ''
    && editPerson.title !== ''
    && editPerson.department_txt !== '';




  const isPhoneAlreadyInUse = participants.find(obj => parseInt(obj.id) !== parseInt(editPerson.id) && obj.cellphone === editPerson.cellphone);

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;
    const isValid = /^[0-9+]*$/.test(inputValue);

    if (isValid) {
      setEditPerson({ ...editPerson, cellphone: inputValue });
      setErrorErrorCellphoneFormat('');
    } else {
      setEditPerson({ ...editPerson, cellphone: inputValue });
      setErrorErrorCellphoneFormat('Please enter a valid phone number.  ');
    }
  };




  // moved this from the api call below
  useEffect(() => {
    if (addedID) {
      getParticipants();
      setCanUseCacheData(true)

      setCurrentPerson(addedID)
      handlePersonChange(addedID)

    }
  }, [addedID, participants]);


  const handleEditPerson = async (e) => {
    e.preventDefault();

    if (!isFormValid) {
      return;
    }

    const timestamp = new Date().toISOString();
    const updatedbyLog = `[${user.first} ${user.last} (${timestamp})] ADD PERSON`;


    setCanUseCacheData(false); //disable cache data while updating person
    setPersonWasUpdated(true);//disable button while updating person


    const config = {
      method: "post",
      url: `${receptionDeskVars.setup.apiUrl}/addAndUpdatePart`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: { ...editPerson, misc: updatedbyLog },
    };

    try {
      const response = await axios(config);

      if (response.status === 200) {
        console.log('API Response:', config, response.data);
        const newID = response.data.updatedPart;

        setAddedID(newID)//this will trigger the useEffect above

      } else {
        // Handle API error here
        console.error('API Error:', response.statusText);

        // setCanUseCacheData(true)

      }
    } catch (error) {
      console.error('API Request Failed:', error);
    }

  }




  return (
    <div className={`person-details-card ${isPersonCardVisible ? 'show-person-card' : 'hide-person-card'}`}>
      <div className="person-details-top-row" onClick={() => {
        navigate(`/home/search/reset`);

      }}>
        <div className="person-details-top-row-content">
          &nbsp; <i className={receptionDeskVars.faicons.addPersonHeaderIcon}></i> {receptionDeskVars.text.misc.addPersonHeaderPrefix}  &nbsp; {editPerson.first_name} {editPerson.last_name}
        </div>
        CANCEL <i className={`fa ${isPersonCardVisible ? 'fa-regular fa-circle-xmark' : 'fa-regular fa-circle-xmark'}`} aria-hidden="true"></i>
      </div>
      <div className="person-details-middle-row">
        <div className="person-details-box-left">
          <form className='edit-card' noValidate>
            <div className="form-group">
              {/* <label htmlFor="first_name">FIRST NAME:</label> */}
              <input
                type="text"
                className="user-input-field"
                id="first_name"
                value={editPerson.first_name}
                onChange={(e) => setEditPerson({ ...editPerson, first_name: e.target.value })}
                placeholder={`First Name`}
                autoComplete="off"
                spellCheck={false}
                required
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="last_name">LAST NAME:</label> */}
              <input
                type="text"
                className="user-input-field"
                id="last_name"
                value={editPerson.last_name}
                onChange={(e) => setEditPerson({ ...editPerson, last_name: e.target.value })}
                placeholder={`Last Name`}
                autoComplete="off"
                spellCheck={false}
                required
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="cellphone">CELL PHONE: {isPhoneAlreadyInUse ? <span className="error-message"> &nbsp; Sorry, this cellphone number already in use</span> : ''}</label> */}
              <label htmlFor="cellphone"><b>ENTER VALID CELLPHONE - NO SPACES! (e.g. 9495551212 or +4445556668886)</b></label>
              <input
                type="text"
                className="user-input-field"
                id="cellphone"
                value={editPerson.cellphone}
                // onChange={(e) => setEditPerson({ ...editPerson, cellphone: e.target.value })}
                onChange={handlePhoneNumberChange}
                placeholder={`Cell Phone`}
                autoComplete="off"
                spellCheck={false}
                required
              />
              <label htmlFor="cellphone"> <span className="error-message">{errorCellphoneFormat}</span>  {isPhoneAlreadyInUse ? <span className="error-message">Sorry, this cellphone number already in use</span> : ''}</label>
            </div>
          </form>
        </div>
        <div className="person-details-box-right">
          <form className='edit-card' noValidate>
            <div className="form-group">
              {/* <label htmlFor="title">TITLE:</label> */}
              <input
                type="text"
                className="user-input-field"
                id="title"
                value={editPerson.title}
                onChange={(e) => setEditPerson({ ...editPerson, title: e.target.value })}
                placeholder={`Title`}
                autoComplete="off"
                spellCheck={false}
                required
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="department_txt">DEPARTMENT:</label> */}
              <input
                type="text"
                className="user-input-field"
                id="department_txt"
                value={editPerson.department_txt}
                onChange={(e) => setEditPerson({ ...editPerson, department_txt: e.target.value })}
                placeholder={`Deparment`}
                autoComplete="off"
                spellCheck={false}
                required
              />
            </div>
            <br />
            <button
              disabled={!isFormValid || isPhoneAlreadyInUse || personWasUpdated}
              type="button"
              className={`btn btn-${!isFormValid ? 'primary' : 'primary'}`}
              onClick={handleEditPerson}>
              {/* {`${receptionDeskVars.buttons.signIn}`} */}
              ADD PERSON
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}








export default CreatePerson;
