import React, { useState, useEffect, useCallback } from 'react';
// import { PieChart } from '@mui/x-charts/PieChart';
import axios from "axios";
import { receptionDeskVars } from './../2023config/2023Vars';
import PieChartWithCenterLabel from './PieChartWithCenterLabel';


const HeroCard = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slideDirection, setSlideDirection] = useState(1);

    const [activityData, setActivityData] = useState([]);

    const [touchStartX, setTouchStartX] = useState(null);
    const [touchEndX, setTouchEndX] = useState(null);
    const [isTouching, setIsTouching] = useState(false);

    useEffect(() => {

        const config = {
            method: "post",
            url: `${receptionDeskVars.setup.apiUrl}/getLiveChartDataSet`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: {}
        };


        axios(config).then((response) => {

            if (response.status === 200) {
                console.log('-----chart_activity------', response.data.chart_activity);

                setActivityData(response.data.chart_activity);
            } else {
                console.log('Server returned an error');
            }
        }).catch((error) => {
            console.error('Error pinging server:', error);
        });



    }, []);


    useEffect(() => {
        // After the component has mounted, set currentSlide to 0
        setCurrentSlide(0);
    }, []);

    const navigateSlide = useCallback((direction) => () => {
        // Calculate the next slide index based on the direction
        const nextSlide = (currentSlide + direction + slides.length) % slides.length;

        setCurrentSlide(nextSlide);
    }, [currentSlide]);


    useEffect(() => {
        // Create a timer to auto-advance the slider
        let timer;

        if (!isTouching) {
            timer = setInterval(() => {
                navigateSlide(1)();
            }, 5000); // Change slide every 5 seconds
        }

        // Clear the timer when the component unmounts
        return () => {
            clearInterval(timer);
        };
    }, [isTouching, navigateSlide]);


    const handleTouchStart = (e) => {
        setIsTouching(true);
        setTouchStartX(e.touches[0].clientX);
        setTouchEndX(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        if (touchStartX === null) {
            return;
        }
        setTouchEndX(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStartX === null || touchEndX === null) {
            return;
        }
        const touchDistance = touchEndX - touchStartX;
        const minTouchDistance = 50; // Adjust as needed to control sensitivity

        if (touchDistance > minTouchDistance) {
            navigateSlide(-1)();
        } else if (touchDistance < -minTouchDistance) {
            navigateSlide(1)();
        }

        setIsTouching(false); // Touch ended, so reset the touch status
        setTouchStartX(null);
        setTouchEndX(null);
    };


    function putActivtyDataInFormatForChart(dataObject) {
        if (!dataObject) {
            return [];
        }
        const data = Object.entries(dataObject).map((item, itemKey) => {
            // let label = item[0];
            let label = `${limitText(item[0], 12)}`
            // switch (itemKey) {
            //     case 'incoming_activity':  label  =`${item[1]['first_name']} ${item[1]['last_name']}`; break;
            //     default:  label = item[0];
            // }
            if (item[1]['first_name']) {
                label = `${item[1]['first_name'][0]}.${limitText(item[1]['last_name'], 5)}`
            }
            if (item[1]['outgoing']) {
                // label  =`${item[0]} \n[in/${item[1]['incoming']}|out/${item[1]['outgoing']}]`
                label = `${item[0]}`
            }
            if (item[1]['name']) {
                label = `${item[1]['name'] === '' ? '??' : item[1]['name']}`
            }

            // return { label: item[0], value: item[1]['count'] }
            return { label: label, value: parseInt(item[1]['count']) }
        });
        return data;
    }
    // console.log('dbdataSetup------', activityData?.delivery_status_activity ? putActivtyDataInFormatForChart(activityData.delivery_status_activity) : 'nothing', activityData);

    function limitText(text, limit) {
        if (text.length > limit) {
            return text.slice(0, limit) + "...";
        } else {
            return text;
        }
    }


    const slides = [
        {  num: "by Status (1/5)", title: 'Totals By Status', data: putActivtyDataInFormatForChart(activityData?.delivery_status_activity) },
        {  num: "by Country (2/5)", title: 'Totals By Country', data: putActivtyDataInFormatForChart(activityData?.country_activity) },
        {  num: "by Person (3/5)", title: 'Top People Messaged', data: putActivtyDataInFormatForChart(activityData?.sentTo_activity) },
        {  num: "by Day (4/5)", title: 'Totals By Day', data: putActivtyDataInFormatForChart(activityData?.daily_totals_activity) },
        // {  num: "1 of 2", title: 'Total By Replys', data: putActivtyDataInFormatForChart(activityData?.incoming_activity) },
        {  num: "by Sender (5/5)", title: 'Totals By Sender/User', data: putActivtyDataInFormatForChart(activityData?.sentBy_activity) },
    ];

    console.log('dbdataSetup------', slides, activityData);


    return (
        <>
            <div className="message-nav">
                <div className="mn-header sticky">
                    <div className="mn-sub-header-container">
                        <strong>CANON RSNA Live <i className='fa-solid fa-angles-right'></i>  {(slides[0]?.title) ? slides[currentSlide].title : 'Loading...'}</strong>
                    </div>
                </div>
            </div>


            <div className="hero-slider">
                <button className="slider-arrow prev" onClick={navigateSlide(-1)}>
                    &#10094;
                </button>
                <div
                    className="slider-container"
                    style={{
                        transform: `translateX(${slideDirection === 1 ? '-' : ''}${currentSlide * 100}%)`,
                    }}

                    onTouchStart={(e) => handleTouchStart(e)}
                    onTouchMove={(e) => handleTouchMove(e)}
                    onTouchEnd={() => handleTouchEnd()}

                >
                    {slides.map((slide) => (
                        <div className="slide" key={slide.id}>

                            <div className='mychart-outerdiv'key={slide.title}>
                                <PieChartWithCenterLabel key={slide.num}  data={formatData(slide.data)} title={slide.title} num={slide.num}  />
                            </div>

                        </div>
                    ))}
                </div>
                <button className="slider-arrow next" onClick={navigateSlide(1)}>
                    &#10095;
                </button>
            </div>
        </>
    );
};


const formatData = (data) => {
    const colorPicker = [
        { color: '#3366cc' },
        { color: '#dc3912' },
        { color: '#e67300' },
        { color: '#109618' },
        { color: '#0099c6' },
        { color: '#f4359e' },
        { color: '#994499' },
        { color: '#aaaa11' },
        { color: '#6633cc' },
        { color: '#b77322' },
        { color: '#16d620' },
        { color: '#b91383' },
    ];

    // Calculate the total sum of all values
    const total = data.reduce((sum, item) => sum + item.value, 0);

    // Create a new array with updated labels and colors
    const newData = data.map((item, index) => ({
        value: item.value,
        // label: `${((item.value / total) * 100).toFixed(0)}%  ${item.label}  (${item.value})`,
        label: `${((item.value / total) * 100).toFixed(0)}%  ${item.label}`,
        color: colorPicker[index % colorPicker.length].color,
    }));
    return newData;
}



export default HeroCard;
