/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useHelpers } from "./../2023hooks/Hooks";
import axios from "axios";
import { receptionDeskVars } from './../2023config/2023Vars';
import Person from './Person';
import CreatePerson from './CreatePerson';



const Messages = ({ messagesState, participantState, useLatestMessagesSingleton, user }) => {



  let { personID } = useParams();
  const navigate = useNavigate();

  //Messages-State Vars and Functions
  const { currentPerson, setCurrentPerson, getPersonApi, useParticipantMessagesSingleton } = messagesState;
  const currentPersonRecord = currentPerson ? getPersonApi(parseInt(currentPerson)) : []; //Set the current person record


  //Get the ALL the participants in an array from the participantState so we can map to messages
  const { participants, setParticipants, canUseCacheData, setCanUseCacheData, getParticipants } = participantState;


  //This is for the editing person -- in the form of a dropdown card overlay - not used right now
  const [isPersonCardVisible, setPersonCardVisiblilty] = useState(false);
  const togglePersonCardVisibility = () => { setPersonCardVisiblilty(prevState => !prevState); };


  //This is a state so that we can trigger hiding anything we need while api is loading
  const [shouldStartMessageBlockAnimations, setShouldStartMessageBlockAnimations] = useState(false);
  /**
   * This is for giving a slight delay so that the ui can update - also triggers an animation in the interim.
   * @param {function} setShouldStartMessageBlockAnimations - Set the state animating/changing.
   * @param {number} [delay=1000] - The delay in milliseconds before stopping the message block animation.
   */
  function triggerMessageBlockAnimation(setShouldStartMessageBlockAnimations, delay = 1000) {
    setShouldStartMessageBlockAnimations(true);
    setTimeout(() => {
      setShouldStartMessageBlockAnimations(false);
    }, delay);
  }


  //API HOOK signalton -  for getting the messages for the current person
  const { updatePIDAndRestartPolling, cyclePartMessagePool } = useParticipantMessagesSingleton


  //API HOOK signalton -  for getting everybodys latest messages
  const { latestMessagesPool, isLatestMessagesPoolCycling, cache_latest_messages, unreadCount } = useLatestMessagesSingleton;


  //This is to setup the latest messages pool - if we have a pool, use it, otherwise use the cache
  let latestMessageResource = cache_latest_messages;
  if (latestMessagesPool && !isLatestMessagesPoolCycling) { latestMessageResource = latestMessagesPool; }




  // ONLOAD we get the current persons messages
  useEffect(() => {
    if (currentPerson) {
      updatePIDAndRestartPolling(currentPerson);// get the person who is set -  messages and start polling
      triggerMessageBlockAnimation(setShouldStartMessageBlockAnimations, 250);// pause for animation of change
    }
  }, []);


  // this is for when we add a new person - so we see the card open
  useEffect(() => {
    // if (currentPerson) {
    if (parseInt(currentPerson) === 0) {
      setPersonCardVisiblilty(true);
    }
    // }
  });



  //ONCLICK we set the new person and get their messages
  const handlePersonChange = (newPID) => {
    if (newPID === currentPerson) return;
    cyclePartMessagePool([]); // clear the messages so we don't see the old ones/jumping around on change
    setCurrentPerson(newPID); // set the new person
    updatePIDAndRestartPolling(newPID) // get the new persons messages and start polling
    setPersonCardVisiblilty(false);

    // triggerMessageBlockAnimation(setShouldStartMessageBlockAnimations, 500)// pause for animation of change
    console.log('____handlePersonChange', newPID)
  }



  return (
    <div className="messages-content">
      <div className="message-nav">

        <MessageNavCards
          //Overall Nav Card Props
          shouldStartMessageBlockAnimations={shouldStartMessageBlockAnimations}
          latestMessageResource={latestMessageResource}
          isLatestMessagesPoolCycling={isLatestMessagesPoolCycling}
          unreadCount={unreadCount}

          //Nav Card Props
          handlePersonChange={handlePersonChange}
          participants={participants}
          currentPersonRecord={currentPersonRecord}
        />

      </div>

    </div>
  );
};






/***** MessagesNav *****/
const CurrentPersonNavCard = ({ currentPersonRecord }) => {
  const { UserInitials } = useHelpers();
  if (currentPersonRecord.id === undefined) return

  return (
    <div className='current-nav-block'>
      <div className="mn-header">
        <div className="mn-sub-header-container">
          <strong>{`${receptionDeskVars.text.misc.selectedPerson}`}</strong>
        </div>
      </div>

      <div className={`mn-current-card ${!currentPersonRecord.last_name ? 'no-person' : ''}`}>
        <div className="card-icon-row">
          <UserInitials prefix={`${receptionDeskVars.initialPrefix.currentCard}`} name={`${currentPersonRecord.first_name} ${currentPersonRecord.last_name}`} />
        </div>
        <div className="card-name">{`${currentPersonRecord.first_name} ${currentPersonRecord.last_name}`}</div>
      </div>
    </div>
  );
}



const MessageNavCards = ({
  shouldStartMessageBlockAnimations, latestMessageResource, isLatestMessagesPoolCycling, unreadCount,
  handlePersonChange, participants, currentPersonRecord
}) => {


  const mnContentRef = useRef(null);
  const handleGoTotop = () => {
    // mnContentRef.current.scrollTop = mnContentRef.current.scrollHeight;
    mnContentRef.current.scrollIntoView({ behavior: 'smooth' }); // You can use 'auto' for instant scroll

  }

  const unMarkedIds = latestMessageResource
    .filter(card => card?.marked && parseInt(card?.marked) === 0)
    .map(card => parseInt(card?.pid));

  //this is so we can just show one unread message per person
  const addedPids = new Set();
  const addedMids = new Set();
  const pidCounts = new Map(); // Use a Map to store pid counts
  latestMessageResource.forEach(card => {
    const pid = parseInt(card?.pid);
    const mid = parseInt(card?.id);

    if (card?.marked && parseInt(card.marked) === 0) {
      const currentCount = pidCounts.get(parseInt(card.pid)) || 0;
      pidCounts.set(parseInt(card.pid), currentCount + 1);
    }
    if (card?.marked && parseInt(card.marked) === 0 && !addedPids.has(pid)) {
      addedPids.add(pid); // Mark the pid as added -- this just makes sure we don't show the same unread person twice
      addedMids.add(mid); // Mark the one message id so we know to show it
    }
  });


  // parseInt(count) === 0)
  console.log('*********unMarkedIds', unMarkedIds)

  return (
    <>
      {/* {unreadCount > 0
        ?
        <div className="mn-header sticky">
          <div className="mn-sub-header-container">
            <strong>{`${receptionDeskVars.text.misc.unreadMessages}`}</strong>

            <div className="unread-messages-outer" onClick={handleGoTotop}>
              <span className={`unread-messages-badge mb-always-on`}>
                {unreadCount}
              </span>
            </div>

          </div>

          <div className="details-info">
            <i className={`scrollTo ${receptionDeskVars.faicons.scrollto}`} onClick={handleGoTotop}></i>
          </div>


        </div>
        : ''} */}


      <div className={`mn-messages ${shouldStartMessageBlockAnimations ? 'mn-messages-lock' : ''} `} >
        <span ref={mnContentRef}></span>

        {/* {
          (latestMessageResource.length > 0)
            ? latestMessageResource.map(card => (

              parseInt(card.marked) === 0 && addedMids.has(parseInt(card.id)) ?

                <NavCard
                  key={`${card.id}-${card.pid}-${card?.timestamp}-A`}
                  cardData={card}
                  selectedCard={currentPersonRecord.id}
                  person={participants.find(part => parseInt(part.id) === parseInt(card.pid))}
                  handlePersonChange={handlePersonChange}
                  pidCount={pidCounts.get(parseInt(card.pid))} // Get pid count from Map
                />
                : ''
            )

            )
            : <div className={`no-messages-container`}>{isLatestMessagesPoolCycling ? 'Loading...' : 'No Messages'}</div>} */}


        <div className="mn-header sticky">
          <div className="mn-sub-header-container">
            <strong>{`${receptionDeskVars.text.misc.latestMessage}`}</strong>
          </div>
        </div>


        {
          (latestMessageResource.length > 0)
            ? latestMessageResource.map(card => (

              parseInt(card.marked) === 0 || unMarkedIds.includes(parseInt(card.pid) ) ? '' :

                <NavCard
                  key={`${card.id}-${card.pid}-${card?.timestamp}-B-${Math.random()}`}
                  cardData={card}
                  selectedCard={currentPersonRecord.id}
                  person={participants.find(part => parseInt(part.id) === parseInt(card.pid))}
                  handlePersonChange={handlePersonChange}
                />
            )

            )
            : <div className={`no-messages-container`}>{isLatestMessagesPoolCycling ? 'Loading...' : 'No Messages'}</div>}


      </div>

    </>
  );
}



function NavCard({ cardData, person, selectedCard, handlePersonChange, pidCount }) {
  const navigate = useNavigate();
  const { UserInitials, formatThisDate } = useHelpers();

  if (cardData.message.length === 0) return // if no message, don't show card
  const messageTXT = (cardData.marked) ? cardData.message : JSON.parse(cardData.message).Body

  if (!person) return console.log('person not found', cardData, person)

  const participantInitialsPrefix = (cardData.marked) ? `${receptionDeskVars.initialPrefix.incoming}` : `${receptionDeskVars.initialPrefix.outgoing}`
  const userInitialsPrefix = (cardData.marked) ? `${receptionDeskVars.initialPrefix.outgoing}` : `${receptionDeskVars.initialPrefix.incoming}`

  const statusCode = (cardData.delivery_status && cardData.delivery_status != '') ? <span className={`status-code status-code-${cardData.delivery_status}`}>{cardData.delivery_status}</span> : '';

  const deskTypeFlag = (cardData?.deskType == 1) ? <i className={`${receptionDeskVars.faicons.usa} `}></i> : <i className={`${receptionDeskVars.faicons.globe} `}></i>;
  const deskTypeOutput = (cardData?.deskType && cardData.deskType != 0) ? <span style={{}}>- {deskTypeFlag}</span> : '';


  return (
    <div className={`nav-card  ${person.id === selectedCard ? 'selected-card' : (!cardData.marked) ? '' : 'incoming-unread-card'}`}
      onClick={() => {
        // navigate(`/home/messages/${person.id}`);
        // handlePersonChange(person.id)
      }}
      disabled={true}
    >
      <div className="initials-column">
        <UserInitials prefix={`${participantInitialsPrefix}`} name={`${person.first_name} ${person.last_name}`} />
      </div>
      <div className="details-column">
        <div className="details-header">
          <div className="details-name">
            {`${person.first_name} ${person.last_name}`}
          </div>
          <div className="details-date">
            {formatThisDate(cardData.timestamp)}
          </div>
        </div>
        <div className="details-last">
          {/* {messageTXT.split(':').pop()} */}
          {cardData?.marked ? messageTXT : messageTXT.split(':').pop()}
          <hr /><sup>{person.title} | {person.department_txt} (#{person.id} / {cardData.id})</sup>
          <br /><sup>Ph. {person.cellphone}</sup>
        </div>
        <div className="details-info">
          {statusCode}
          {userInitialsPrefix}
          {cardData.kiosk}
          {deskTypeOutput}
          <UserInitials prefix={`${userInitialsPrefix}`} name={`${cardData.kiosk}`} />
          {cardData.marked ? <i className={`${receptionDeskVars.faicons.incomingMessageNavIcon}`}></i> : <i className={`${receptionDeskVars.faicons.outgoingMessageNavIcon}`}></i>}
        </div>
      </div>

      {/* <div className={`undread-overlay ${parseInt(cardData.marked) === 0 ? 'visible' : ''}`}>- {pidCount} UNREAD -</div> */}
    </div>
  );
};













/***** MessagesTimeline *****/
const MessagesTimelineContent = (
  {
    useParticipantMessagesSingleton,
    currentPersonRecord,
    shouldStartMessageBlockAnimations,
    user,
    setPersonCardVisiblilty,
    isAddingParticipant
  }
) => {

  const { partMessagesPool, isPartMessagesPoolCycling, cyclePartMessagePool } = useParticipantMessagesSingleton

  const handleClear = () => {
    setMessage(''); // Clear the message
  };
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState('');
  const sendMessage = async () => {
    if (!message) return; // Don't send an empty message

    setIsSending(true);
    const newMessageData = {
      message: message,
      pid: currentPersonRecord.id,
      type: 0,
      akid: 1,
      // kiosk: "Sammy Davis",
      kiosk: user.first + ' ' + user.last,
    }

    const config = {
      method: "post",
      url: `${receptionDeskVars.setup.apiUrl}/sendMessage`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: newMessageData
    };





    try {
      const response = await axios(config);
      console.log(response.data);
      // Handle the response as needed, e.g., clearing the message input

      console.log('----->newMessageData', [...partMessagesPool, newMessageData])
      cyclePartMessagePool([...partMessagesPool, newMessageData])
      handleClear();
      setTimeout(() => {
        setIsSending(false);
      }, `${receptionDeskVars.setup.intervals.sendTextPauseBeforeCanTextAgain}`); // 3000 milliseconds = 3 seconds

    } catch (error) {
      console.error('Error sending message:', error);
      // Handle the error as needed
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };



  // const unMarkedIds = partMessagesPool
  //   .filter(card => card?.marked && parseInt(card?.marked) === 0)
  //   .map(card => parseInt(card?.id));

  const unMarkedIds = partMessagesPool
    .reduce((lastUnmarkedId, card) => {
      if (parseInt(card?.marked) === 0) {
        return parseInt(card?.id);
      }
      return lastUnmarkedId;
    }, null);


  // console.log('*******ddddddd**unMarkedIds', unMarkedIds)
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };



  const mpContentRef = useRef(null);
  useEffect(() => {
    if (unMarkedIds) {
      scrollToElement(`${receptionDeskVars.setup.messagebubblePrfx}${unMarkedIds}`);
    } else if (mpContentRef.current) {
      mpContentRef.current.scrollTop = mpContentRef.current.scrollHeight;
    }

  }, [partMessagesPool, shouldStartMessageBlockAnimations]); // Empty dependency array to run only on mount



  return (
    <>
      <div className="mp-header" onClick={() => setPersonCardVisiblilty(false)}>
        <MessagesTimelineHeader currentPersonRecord={currentPersonRecord} isAddingParticipant={isAddingParticipant} />
      </div>


      <div className="mp-content" ref={mpContentRef} onClick={() => setPersonCardVisiblilty(false)} >
        {partMessagesPool.map((partMess, idx, arr) => {
          let spacer = '';

          if (arr.length - 1 === idx) {
            spacer = <div><br /><br /><br /><br /><br /><br /><br /></div>;
          }

          return (
            <MessagesBubble
              key={idx}
              message={partMess}
              currentPersonRecord={currentPersonRecord}
              isPartMessagesPoolCycling={isPartMessagesPoolCycling}
              shouldStartMessageBlockAnimations={shouldStartMessageBlockAnimations}
              spacer={spacer}
            />
          )

        }
        )

        }
      </div>

      {/* <div className="mp-content" ref={mpContentRef} onClick={() => setPersonCardVisiblilty(false)} >
        {partMessagesPool.length > 0
          ? (partMessagesPool.map((partMess, idx, arr) => {
            let spacer = '';

            if (arr.length - 1 === idx) {
              spacer = <div><br /><br /><br /><br /><br /><br /><br /></div>;
            }


            return (
              <MessagesBubble
                key={idx}
                message={partMess}
                currentPersonRecord={currentPersonRecord}
                isPartMessagesPoolCycling={isPartMessagesPoolCycling}
                shouldStartMessageBlockAnimations={shouldStartMessageBlockAnimations}
                spacer={spacer}
              />
            )

          }
          )
          )

          // : <div className={`no-messages-container`}>{isPartMessagesPoolCycling ? 'Loading...' : 'No Messages'}</div>
          : <div className={`no-messages-container`}>{`${receptionDeskVars.text.misc.messagesNoResults}`}</div>
        }
      </div> */}


      <div className="mp-footer" onClick={() => setPersonCardVisiblilty(false)}>
        <MessagesTimelineFooter
          currentPersonRecord={currentPersonRecord}
          message={message}
          setMessage={setMessage}
          handleClear={handleClear}
          handleSendMessages={sendMessage}
          isSending={isSending}
          shouldStartMessageBlockAnimations={shouldStartMessageBlockAnimations}
          handleKeyDown={handleKeyDown}
        />
      </div>
    </>
  );

}




function MessagesBubble({ message, currentPersonRecord, shouldStartMessageBlockAnimations, spacer }) {
  const { UserInitials, formatThisDate } = useHelpers();

  const [isMessageRead, setIsMessageRead] = useState(parseInt(message.marked) === 1); // State to store the input value
  const handleCheckboxChange = (event) => {
    setIsMessageRead(event.target.checked);
    sendMessage()
    // console.log('+++++++++++++++++++handleCheckboxChange', event.target.checked)
  };

  const sendMessage = async () => {
    if (!message) return; // Don't send an empty message

    const config = {
      method: "post",
      url: `${receptionDeskVars.setup.apiUrl}/markMessageRead`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        message_id: message.id,
      },
    };


    try {
      const response = await axios(config);
      console.log("+++++++++++++++++++", response.data);

    } catch (error) {
      console.error('Error sending message:', error);
      // Handle the error as needed
    }
  };


  const personInitials = <UserInitials prefix={`${receptionDeskVars.initialPrefix.outgoing}`} name={`${currentPersonRecord.first_name} ${currentPersonRecord.last_name}`} />;
  const userInitials = <UserInitials prefix={`${receptionDeskVars.initialPrefix.incoming}`} name={`${message?.kiosk}`} />;

  const messageType = (message.marked) ? 'inbound' : 'outbound';

  if (message.message.length === 0) return // if no message, don't show card
  const messageTXT = (message.marked || !message.delivery_data) ? message.message : JSON.parse(message.message).Body

  const dateTXT = (!message.delivery_data) ? 'Sending...' : formatThisDate(message.timestamp)
  const statusCode = (message.delivery_status && message.delivery_status != '') ? <span className={`status-code status-code-${message.delivery_status}`}>{message.delivery_status}</span> : '';


  return (
    <div id={`${receptionDeskVars.setup.messagebubblePrfx}${message?.id}`} className={`message-block ${shouldStartMessageBlockAnimations ? 'mb-loading' : ''}`}>
      <div className="message-time-date">
        {dateTXT}
      </div>
      <div className={`message-text ${messageType}-message `}>
        {messageType === 'inbound' ? personInitials : ''}
        <div className={`message-text-contents ${parseInt(message.marked) === 0 ? 'unread-incoming-message' : ''}`}>

          <div className={`message-text-contents-text-container`}>
            {/* {messageTXT.split(':').pop()} */}
            {message?.marked ? messageTXT : messageTXT.split(':').pop()}
            <br />
            {statusCode}
          </div>
          <div className={`unread-checkbox-container ${messageType === 'outbound' || parseInt(message.marked) === 1 ? 'unread-checkbox-hidden' : ''}`}>
            <div className="toggle-switch" title='Click to Mark Read'>
              <input type="checkbox"
                id={message.id}
                className="toggle-input"
                onChange={handleCheckboxChange} // Add the onChange event handler
                checked={isMessageRead} // Control the checkbox with the state
                disabled={isMessageRead}
              />
              <label htmlFor={message.id} className="slider"></label>
              <label htmlFor={message.id} className='toggle-switch-bottom-label'>
                {isMessageRead ? 'Read' : 'Unread'}
              </label>
            </div>
          </div>

        </div>
        {messageType === 'outbound' ? userInitials : ''}
      </div>
      {spacer}
    </div>

  );
}




function MessagesTimelineHeader({ currentPersonRecord, isAddingParticipant }) {
  const { UserInitials } = useHelpers();
  const formatedPhoneNumber = currentPersonRecord?.cellphone ? formatPhoneNumber(currentPersonRecord.cellphone) : '';
  return (
    <>
      <div className="mp-header-icon">

        {currentPersonRecord?.last_name
          ? <UserInitials prefix={`${receptionDeskVars.initialPrefix.currentCard}`} name={`${currentPersonRecord.first_name} ${currentPersonRecord.last_name}`} />
          : <UserInitials prefix={`${receptionDeskVars.initialPrefix.unkknowUserNamePrefix}`} name={`${receptionDeskVars.initialPrefix.unkknowUserName}`} />
        }

        {/* <i className={
          currentPersonRecord?.last_name
            ? `${receptionDeskVars.faicons.partInfoIcon}`
            : `${receptionDeskVars.faicons.partInfoErrorIcon}`
        }></i> */}
      </div>
      <div className="mp-header-name">
        <div className="mp-header-name-top">
          {currentPersonRecord?.last_name ? (
            `${currentPersonRecord.first_name} ${currentPersonRecord.last_name}`
          ) : (
            (parseInt(isAddingParticipant) === 0) ? `${receptionDeskVars.text.error.addingParticipant}` : `${receptionDeskVars.text.error.noParticipantName}`
          )}
        </div>
        <div className="mp-header-name-bottom">{currentPersonRecord.title}</div>
      </div>
      <div className="mp-header-phone">{formatedPhoneNumber}</div>
    </>
  );

}




function MessagesTimelineFooter({ currentPersonRecord, message, setMessage, handleClear, handleSendMessages, isSending, shouldStartMessageBlockAnimations, handleKeyDown }) {


  const disabledFlag = !currentPersonRecord?.last_name || shouldStartMessageBlockAnimations ? false : true;
  const placeholderTXT = !currentPersonRecord?.last_name
    ? `${receptionDeskVars.text.error.noParticipantName}`
    : `${receptionDeskVars.text.misc.textMessagePlaceHolder} ${currentPersonRecord.first_name} ${currentPersonRecord.last_name}:`

  return (
    <>
      {/* <div className="text-message-clipboard">
                <button className="clipboard-button btn btn-secondary clear-button" disabled={!disabledFlag || isSending}>
                    <i className={`${receptionDeskVars.faicons.clipBoardIcon}`}></i>
                </button>
            </div> */}

      <input
        type="text"
        className="text-message-input"
        placeholder={`${placeholderTXT}`}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={!disabledFlag || isSending}
        autoFocus
      />

      <div className="text-message-buttons">
        <button className="btn btn-secondary clear-button" onClick={handleClear} disabled={!disabledFlag || isSending}>{`${receptionDeskVars.buttons.messagClear}`}</button>
        <button className="btn btn-primary send-button" onClick={handleSendMessages} disabled={!disabledFlag || isSending}>{`${receptionDeskVars.buttons.send}`}</button>
      </div>
    </>
  );
}




function formatPhoneNumber(phoneNumber) {
  const cleanedNumber = phoneNumber;//phoneNumber.replace(/\D/g, '');

  if (cleanedNumber.charAt(0) !== '+') {
    // Domestic number: (AAA) NNN-NNNN
    const domesticFormat = cleanedNumber.match(/(\d{3})(\d{3})(\d{4})/);
    if (domesticFormat) {
      return `(${domesticFormat[1]}) ${domesticFormat[2]}-${domesticFormat[3]}`;
    }
  }

  return phoneNumber; // Return it as is (international number with a leading '+')
}




export default Messages;
