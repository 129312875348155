/* eslint-disable */
import { useCallback, useState, useEffect, useRef } from 'react';
import { receptionDeskVars } from '../2023config/2023Vars';

import axios from "axios";



let cache = {};
export function useParticipant(setParticipants, canUseCacheData) {
    const config = {
        method: "post",
        url: `${receptionDeskVars.setup.apiUrl}/getParticipantsByLastName`,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {},
    };
    console.log('-------------firing useParticipant---------------------', canUseCacheData, cache);
    const sendPostRequest = async () => {
        if (
            Object.keys(cache).length > 0 &&
            Date.now() < cache.timestamp + receptionDeskVars.setup.cacheTimeOut &&
            canUseCacheData
        ) {
            setParticipants(cache.dbData);//I don't think we need to add the cached data to state here -- just keep using the data in state
            console.log(
                "c-  Parts: using CACHE participant data",
                { 'now': readableDate(Date.now()) },
                { 'expires at': readableDate(cache.timestamp + receptionDeskVars.setup.cacheTimeOut) }
            );
        } else {
            try {
                const resp = await axios(config);
                cache = {
                    timestamp: Date.now(),
                    dbData: Object.values(resp.data.results),
                };

                setParticipants(Object.values(resp.data.results));
                console.log(
                    "c+  Parts: using NEW! participant data",
                    { 'now': readableDate(Date.now()) },
                    { 'next expiration': readableDate(cache.timestamp + receptionDeskVars.setup.cacheTimeOut) },
                );
            } catch (err) {
                console.error(err);
            }
        }
    };

    // console.log("--useParticipant HOOK--");
    return sendPostRequest;
}

// let cache = {};
// export function useParticipant(setParticipants, canUseCacheData = true) {
//     const config = {
//         method: "post",
//         url: `${receptionDeskVars.setup.apiUrl}/getParticipantsByLastName`,
//         headers: {
//             "Content-Type": "application/x-www-form-urlencoded",
//         },
//         data: {},
//     };
//     console.log('-------------firing useParticipant---------------------', canUseCacheData, cache);
//     const sendPostRequest = async () => {
//         if (
//             Object.keys(cache).length > 0 &&
//             Date.now() < cache.timestamp + receptionDeskVars.setup.cacheTimeOut &&
//             canUseCacheData
//         ) {
//             setParticipants(cache.dbData);//I don't think we need to add the cached data to state here -- just keep using the data in state
//             console.log(
//                 "c-  Parts: using CACHE participant data",
//                 { 'now': readableDate(Date.now()) },
//                 { 'expires at': readableDate(cache.timestamp + receptionDeskVars.setup.cacheTimeOut) }
//             );
//         } else {
//             try {
//                 const resp = await axios(config);
//                 cache = {
//                     timestamp: Date.now(),
//                     dbData: Object.values(resp.data.results),
//                 };

//                 setParticipants(Object.values(resp.data.results));
//                 console.log(
//                     "c+  Parts: using NEW! participant data",
//                     { 'now': readableDate(Date.now()) },
//                     { 'next expiration': readableDate(cache.timestamp + receptionDeskVars.setup.cacheTimeOut) },
//                 );
//             } catch (err) {
//                 console.error(err);
//             }
//         }
//     };

//     // console.log("--useParticipant HOOK--");
//     return sendPostRequest;
// }




let cache_region = {};
export function useRegions(setRegions) {
    const config = {
        method: "post",
        url: `${receptionDeskVars.setup.apiUrl}/getRegions`,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {},
    };

    const sendPostRequest = async () => {
        if (
            Object.keys(cache_region).length > 0 &&
            Date.now() < cache.timestamp + receptionDeskVars.setup.cacheTimeOut
        ) {
            setRegions(cache_region.dbData);
            console.log(
                "c-  Region: using cache_region data",
                { 'now': readableDate(Date.now()) },
                { 'expires at': readableDate(cache_region.timestamp + receptionDeskVars.setup.cacheTimeOut) }
            );
        } else {
            try {
                const resp = await axios(config);
                cache_region = {
                    timestamp: Date.now(),
                    dbData: Object.values(resp.data.results),
                };
                setRegions(Object.values(resp.data.results));
                console.log(
                    "c+  Region: using NEW! region data",
                    // Object.values(resp.data.results),
                    { 'now': readableDate(Date.now()) },
                    { 'next expiration': readableDate(cache_region.timestamp + receptionDeskVars.setup.cacheTimeOut) },
                );
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        }
    };

    // console.log("useRegions");

    return sendPostRequest;
}




let cache_latest_messages = [];
export function useLatestMessages() {
    const [latestMessagesPool, cycleLatestMessagePool] = useState(null);
    const [pollOutgoingTimestamp, setOutgoingPollTimestamp] = useState(null);
    const [pollIncomingTimestamp, setIncomingPollTimestamp] = useState(null);
    const [unreadCount, setUnreadCount] = useState(null);
    const [isLatestMessagesPoolCycling, setIsLatestMessagesPoolCycling] = useState(false);
    const [error, setError] = useState(null);
    const isSubscribed = useRef(true);

    const interval = 15000
    const configTest = {
        method: "post",
        url: `${receptionDeskVars.setup.apiUrl}/pollingReport`,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {},
    };

    const config = {
        method: "post",
        url: `${receptionDeskVars.setup.apiUrl}/live_activity_getLatestMessagesSet`,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: {},
    };

    // polling_results
    // 'lastest_outgoing' 	=> $this->getLatestMessagesOutgoing(),
    // 'lastest_incoming' 	=> $this->getLatestMessagesIncoming(),
    // 'unread_incoming' 	=> $this->getAllIncomingUnread()

    // 'last_outgoing_timestamp' 	=> $this->pollLastMessageOutTimestamp(),
    // 'last_incoming_timestamp' 	=> $this->pollLastMessageInTimestamp(),
    // 'unread_incoming_count' 	=> $this->pollUnreadIncomingCount()


    const fetchTestTimestamp = () => {
        if (isLatestMessagesPoolCycling) return Promise.reject('Already loading');

        return axios(configTest).then(response => {
            const newPollOutgoingTimestamp = response.data.polling_results.last_outgoing_timestamp;
            const newPollIncomingTimestamp = response.data.polling_results.last_incoming_timestamp;
            const newPollUnreadCount = response.data.polling_results.unread_incoming_count;

            console.log('...Checking Timestamp... is?', newPollOutgoingTimestamp, ' > ', pollOutgoingTimestamp);

            if (
                newPollOutgoingTimestamp !== pollOutgoingTimestamp ||
                newPollIncomingTimestamp !== pollIncomingTimestamp ||
                newPollUnreadCount !== unreadCount
            ) {
                console.log('...Timestamps do not match, fetching new messages.')
                return { newPollOutgoingTimestamp, newPollIncomingTimestamp, newPollUnreadCount };
            }
            return Promise.reject('Timestamps match, no need to fetch new messages.');
        });
    };

    const fetchLatestMessages = (pollingResult) => {
        setIsLatestMessagesPoolCycling(true);
        return axios(config).then(response => {
            console.log('......Received NEW LATEST MESSAGE DATA');//response.data

            setOutgoingPollTimestamp(pollingResult.newPollOutgoingTimestamp);
            setIncomingPollTimestamp(pollingResult.newPollIncomingTimestamp);
            setUnreadCount(pollingResult.newPollUnreadCount);

            console.log('......Saving NEW timestamps for next poll')
            cycleLatestMessagePool(prepLatestMessagesPool(response.data.polling_results));
            setIsLatestMessagesPoolCycling(false);

            return response;
        });
    };

    const prepLatestMessagesPool = (pollingResult) => {
        const outgoingOrder = pollingResult?.lastest_outgoing ? Object.values(pollingResult.lastest_outgoing).reverse() : [];
        const incomingOrder = pollingResult?.lastest_incoming ? Object.values(pollingResult.lastest_incoming).reverse() : [];
        const unreadOrder = pollingResult?.unread_incoming ? Object.values(pollingResult.unread_incoming).reverse() : [];
        
        const merged = simple_mergeAndSortByTimestamp(outgoingOrder, incomingOrder);
        // const merged = mergeAndSortByTimestamp(outgoingOrder, incomingOrder);
        // const merged = mergeArraysByNewestTimestampOptimized(outgoingOrder, incomingOrder);
        // const latestMessageOrdered = [...unreadOrder, ...incomingOrder, ...outgoingOrder];
        const latestMessageOrdered = [...unreadOrder, ...merged];

        // console.log('incomingOrder-=-=-=-=-=-=-=-==-==-', incomingOrder)

        cache_latest_messages = latestMessageOrdered;/// put in cache for use when loading
        console.log('......Preping LatestMessagesPool after fresh pull for State update', { prepAndMerged: merged });
        console.log('......*************************', latestMessageOrdered);

        return latestMessageOrdered // Object.values({...pollingResult.lastest_outgoing})
        return  // Object.values({...pollingResult.lastest_outgoing})
    }


    useEffect(() => {
        const fetchData = () => {
            fetchTestTimestamp()
                .then(fetchLatestMessages)
                .catch(error => {
                    // if (isSubscribed.current) {
                    if (error !== 'Timestamps match, no need to fetch new messages.') {
                        setError(error);
                        setIsLatestMessagesPoolCycling(false);
                    }
                    // }
                });
        };

        fetchData(); // Run once on mount

        const intervalId = setInterval(fetchData, interval);
        console.log('[  Starting Polling -> NEW INTERVAL#', intervalId);
        return () => {
            isSubscribed.current = false;
            console.log(']  Ending Polling -> CLEAR INTERVAL#', intervalId);
            clearInterval(intervalId);
        };
    }, [interval, pollOutgoingTimestamp, pollIncomingTimestamp, unreadCount]);

    return { latestMessagesPool, unreadCount, isLatestMessagesPoolCycling, cache_latest_messages, error };
};




export function useParticipantMessages() {
    const [pid, setPID] = useState(null);
    const [partMessagesPool, cyclePartMessagePool] = useState([]);
    const [pollOutgoingTimestamp, setOutgoingPollTimestamp] = useState(null);
    const [pollIncomingTimestamp, setIncomingPollTimestamp] = useState(null);
    const [isPartMessagesPoolCycling, setIsPartMessagesPoolCycling] = useState(false);
    const [error, setError] = useState(null);
    const isSubscribed = useRef(true);
    const intervalIdRef = useRef(null);

    const interval = `${receptionDeskVars.setup.intervals.useParticipantMessages}`


    const fetchTestTimestamp = useCallback(() => {
        console.log('___Testing Part Timestamp', pid)
        if (isPartMessagesPoolCycling) return Promise.reject('Already loading');

        const configTest = {
            method: "post",
            url: `${receptionDeskVars.setup.apiUrl}/participantPollingReport`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: { pid: pid },
        };


        return axios(configTest).then(response => {
            const newPollOutgoingTimestamp = response.data.polling_results.part_outgoing_timestamp;
            const newPollIncomingTimestamp = response.data.polling_results.part_incoming_timestamp;

            console.log('___Checking Part Timestamp... is?', pid, newPollOutgoingTimestamp, ' > ', pollOutgoingTimestamp, configTest, response.data);

            if (
                newPollOutgoingTimestamp !== pollOutgoingTimestamp ||
                newPollIncomingTimestamp !== pollIncomingTimestamp
            ) {
                console.log('___Part Timestamps do not match, fetching new messages. --> Setting new timestamps.')
                setOutgoingPollTimestamp(newPollOutgoingTimestamp);
                setIncomingPollTimestamp(newPollIncomingTimestamp);

                return { newPollOutgoingTimestamp, newPollIncomingTimestamp };
            }
            return Promise.reject('Timestamps match, no need to fetch new messages.');
        });
    }, [pid, isPartMessagesPoolCycling]);



    const prepLatestMessagesPool = useCallback((pollingResult) => {
        const outgoingOrder = pollingResult?.messages_outgoing ? Object.values(pollingResult.messages_outgoing).reverse() : [];
        const incomingOrder = pollingResult?.messages_incoming ? Object.values(pollingResult.messages_incoming).reverse() : [];

        const merged = mergeAndSortByTimestamp(outgoingOrder, incomingOrder).reverse();
        const latestMessageOrdered = merged;//[...merged];

        // cache_latest_messages = latestMessageOrdered;/// put in cache for use when loading
        console.log('......Preping PartMessagesPool after fresh pull for State update', { prepped: latestMessageOrdered });

        return latestMessageOrdered // Object.values({...pollingResult.lastest_outgoing})
    }, []);



    const fetchLatestMessages = useCallback(() => {
        console.log('___Begining -> Fetching NEW Part Message DATA', pid)
        setIsPartMessagesPoolCycling(true);

        const config = {
            method: "post",
            url: `${receptionDeskVars.setup.apiUrl}/getMessagesByPartSet`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: { pid: pid },
        };

        return axios(config).then(response => {
            console.log('a+______Received NEW PART MESSAGE DATA', response.data);//
            // console.log('______Saving NEW PART timestamps for next poll', pid, response.data)
            const prepedResults = prepLatestMessagesPool(response.data.part_results)
            cyclePartMessagePool(prepedResults);
            setIsPartMessagesPoolCycling(false);

            return response;
        });
    }, [pid]);



    // Function to fetch data and handle the entire process
    const fetchData = useCallback(() => {
        if (!pid) return; // Prevent fetching if pid is not set
        console.log('___Begining Testing/Fetching data SET', pid, intervalIdRef)

        fetchTestTimestamp()
            .then(fetchLatestMessages)// .then(prepLatestMessagesPool)
            .catch(error => {
                if (error !== 'Timestamps match, no need to fetch new messages.') {
                    setError(error);
                    setIsPartMessagesPoolCycling(false);
                }
            });
    }, [fetchTestTimestamp, fetchLatestMessages, prepLatestMessagesPool, pid]);




    // Function to start or restart polling
    const startOrRestartPolling = useCallback(() => {
        console.log('___Starting or restarting polling', intervalIdRef)
        clearInterval(intervalIdRef.current); // Clear existing interval
        intervalIdRef.current = setInterval(fetchData, interval); // Start or restart polling
    }, [fetchData, interval]);



    // Expose a method for external components to set PID and restart polling ************ENTRY POINT****************
    const updatePIDAndRestartPolling = useCallback((newPID) => {
        console.log('___Updating PID to', newPID, intervalIdRef)
        setPID(newPID); // Set the new pid
        setOutgoingPollTimestamp(null);
        setIncomingPollTimestamp(null);


        // startOrRestartPolling(); // Restart polling
    }, [startOrRestartPolling]);



    useEffect(() => {
        console.log('___useEffect', pid, intervalIdRef)
        if (pid) {
            console.log('___>>>Fetching data and starting polling', intervalIdRef)
            fetchData(); // Initial fetch
            startOrRestartPolling(); // Start polling
        }

        return () => {
            console.log('___Clearing interval', intervalIdRef)
            clearInterval(intervalIdRef.current); // Clean up on unmount
        };
    // }, [pid]);
    }, [pid, fetchData, startOrRestartPolling]);

    // Expose what's necessary for external components
    return { partMessagesPool, isPartMessagesPoolCycling, error, updatePIDAndRestartPolling, cyclePartMessagePool };

};

















// export function useSendMessage() {
//     console.log('useSendMessage');
//     return
// }



export const useHelpers = () => {
    const getInitials = useCallback((name) => {

        const hasSpace = name.includes(" "); // Checks for a space e.g. "John Doe"
        if (!hasSpace){
            if(name.length >= 2) return name.substring(0, 2).toUpperCase(); //no space, return first two letters
            return "??";//otherwise return ??
        }

        let initials = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        return initials;
    }, []);

    const UserInitials = ({ name, prefix }) => {
      const nameWprefix = prefix ? `${prefix} ${name}` : name;
      return (
            <div className="user-initials" title={nameWprefix}>
                {getInitials(name)}
            </div>
        );
    };


    const formatThisDate = (dateString) => {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const date = new Date(dateString);

        // Get the day of the week from the date object
        const day = days[date.getDay()];

        // Get hours and minutes
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';

        // Convert hours from 24-hour time to 12-hour time format
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        // Minutes should be two digits
        const strMinutes = minutes < 10 ? '0' + minutes : minutes;

        // Concatenate the formatted string
        return `${day}, ${hours}:${strMinutes}${ampm}`;
    }



    return {
        getInitials,
        UserInitials,
        formatThisDate
    };
};



const readableDate = (date) => new Date(date).toLocaleString();




function mergeArraysByNewestTimestampOptimized(array1, array2) {
    const recordMap = new Map();

    const addOrUpdateRecord = (record) => {
        const existingRecord = recordMap.get(record.pid);
        // Compare strings directly if the date format is consistent and correct
        // if ( existingRecord record.timestamp > existingRecord.timestamp) {
        //     recordMap.set(record.pid, record);
        // }
        if (!existingRecord || record.timestamp > existingRecord.timestamp) {
            recordMap.set(record.pid, record);
        }
    };

    // Process all records from both arrays
    array1.forEach(addOrUpdateRecord);
    array2.forEach(addOrUpdateRecord);

    // Convert the map into an array and sort it only if necessary
    if (recordMap.size > 1) {
        return Array.from(recordMap.values()).sort((a, b) => b.timestamp.localeCompare(a.timestamp));
    } else {
        return Array.from(recordMap.values());
    }
}

function mergeAndSortByTimestamp(array1, array2) {
    // Merge the two arrays
    //                 outgoingOrder, incomingOrder
    //                 time_created  time_received
    const mergedArray = [...array1, ...array2];

    // Sort the merged array by the 'timestamp' field
    mergedArray.sort((a, b) => {
        // Assuming the timestamps are in a format that can be directly compared
        // If the timestamp is in a different format, you may need to convert it to a Date object first
        // return new Date(b.timestamp) - new Date(a.timestamp);
        return new Date(b.time_created) - new Date(a.time_received);
    });

    return mergedArray;
}

function simple_mergeAndSortByTimestamp(array1, array2) {
    // Merge the two arrays
    //                 outgoingOrder, incomingOrder
    //                 time_created  time_received
    const mergedArray = [...array1, ...array2];

    // Sort the merged array by the 'timestamp' field
    mergedArray.sort((a, b) => {
        // Assuming the timestamps are in a format that can be directly compared
        // If the timestamp is in a different format, you may need to convert it to a Date object first
        // return new Date(b.timestamp) - new Date(a.timestamp);
        return new Date(b.timestamp) - new Date(a.timestamp);
    });

    return mergedArray;
}

