/* eslint-disable */
// // App.jsx or your main component file
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import Messages from "./2023/Messages";
import Search from "./2023/Search";
import HeroCard from "./2023/HeroCard";
import { receptionDeskVars } from './2023config/2023Vars';

import { useHelpers, useParticipant, useRegions, useLatestMessages, useParticipantMessages } from "./2023hooks/Hooks";

const dummyUser = { first: "Marcello", last: "Hernandez" };


const AppMainContent = () => {

  console.log('<--- APP --->')
  // const { currentUser } = currentUserState;
  const [currentUser, setCurrentUser] = useState(dummyUser);

  //Latest Messages API & Hook as a Singleton
  const { latestMessagesPool, unreadCount, isLatestMessagesPoolCycling, cache_latest_messages, error } = useLatestMessages();
  const useLatestMessagesSingleton = { latestMessagesPool, unreadCount, isLatestMessagesPoolCycling, cache_latest_messages, error }

  console.log('<--- APP --->')
  return (
    <div className="app-container">
      <HeroCard />
      {/* <header className="header"><Header user={currentUser} useLatestMessagesSingleton={useLatestMessagesSingleton} /></header> */}
      <main className="content"><MainContent user={currentUser} useLatestMessagesSingleton={useLatestMessagesSingleton} /></main>
      <footer className="footer"><span>Version: {receptionDeskVars.setup.version}</span></footer>
    </div>
  );
};







const Header = ({ user, useLatestMessagesSingleton }) => {
  console.log('<--- HEADER --->')
  const { UserInitials } = useHelpers();
  const { unreadCount, isLatestMessagesPoolCycling } = useLatestMessagesSingleton

  const unreadMessagesIndicator = unreadCount === 0 ? "" : <Link to={`/home/messages`}> <div className="unread-messages-outer"><span className={`unread-messages-badge ${isLatestMessagesPoolCycling ? 'updated' : ''} mb-always-on mb-small`}>{unreadCount}</span></div></Link>
  const name = `${user.first} ${user.last}`;


  return (
    <header className="header">
      <div className="logo-section">
        <img className="logo" src={`${receptionDeskVars.imgs.headerLogo.file}`} alt={`${receptionDeskVars.imgs.headerLogo.txt}`} />
      </div>


      <div className="user-section">
        <UserInitials name={`${user.first} ${user.last}`} /><div className="user-name">{name}</div>{unreadMessagesIndicator}
      </div>

      <div className="button-section">
        {receptionDeskVars.headerLinks.map((headerLink, index) => {
          const prefixClass = headerLink.addperson ? receptionDeskVars.faicons.addPersonTopHeaderIcon : receptionDeskVars.faicons.caret;
          return (
            <Link key={index} to={`${headerLink.link}`} className="header-nav">
              {/* {headerLink.name}  {headerLink.unread === true ? unreadMessagesIndicator : ''} */}
              <i className={prefixClass}></i> {headerLink.name}
            </Link>
          );
        })}
      </div>

    </header>
  );
};








const MainContent = ({ useLatestMessagesSingleton, user }) => {
  console.log('<--- APP --->')
  let { currentTab } = useParams();

  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchTermDisabled, setIsSearchTermDisabled] = useState(false);
  const [searchType, setSearchType] = useState('byPerson');
  const [searchResults, setSeachResults] = useState([]);
  const [sortConfig, setSortConfig] = useState(receptionDeskVars.setup.defaultSort);

  const [currentPerson, setCurrentPerson] = useState();

  const [canUseCacheData, setCanUseCacheData] = useState(true);
  const [participants, setParticipants] = useState([]);
  const getParticipants = useParticipant(setParticipants, canUseCacheData);
  const [regions, setRegions] = useState([]);
  const getRegions = useRegions(setRegions);




  useEffect(() => {
    getRegions();
    getParticipants();

  }, [searchTerm, getRegions, getParticipants]);




  const getRegionsList = () => {
    return regions.filter(region =>
      participants.some(person => person.regionid === region.regionid)
    );
  }

  const searchAPI = (searchTerm) => {
    const results = performSearch(participants, searchTerm);
    setSeachResults(results);
  }

  const getGroupAPI = (selectedRegion, dataType = "region") => {
    const regionArrayFilter = (f) => f.territory_id === selectedRegion;
    const results = participants.filter(regionArrayFilter).map((p, k) => p);

    setSeachResults(results);
  }

  const getPersonApi = (id) => {
    // console.log('++++++++++++++++++++++++getPersonApi', id, participants)
    const person = participants.find((person) => parseInt(person.id) === id);
    return { ...person };
  }


  const performSearch = (items, searchTerm, searchColumns = ['first_name', 'last_name']) => {
    if (searchTerm === '**ALL**') {//**ALL** is a special search term that returns all items
      return items;
    }


    if (!searchTerm || !searchColumns || !Array.isArray(searchColumns) || searchColumns.length === 0) {
      return [];
    }

    // Escape special characters in the search term
    const escapedTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const lowercasedTerm = escapedTerm.toLowerCase();
    const uniqueIds = new Set();
    const uniqueResults = [];

    items.forEach((item) => {
      if (item.letter_heading) return; // Skip if item has letter_heading

      // Check if any specified string property of the object includes whole words that start with the search term.
      const isMatch = searchColumns.some((columnName) => {
        const value = item[columnName];
        return typeof value === 'string' && value.toLowerCase().match(new RegExp(`\\b${lowercasedTerm}`));
      });

      if (isMatch && !uniqueIds.has(item.cellphone)) {
        uniqueIds.add(item.cellphone);
        uniqueResults.push(item);
      }
    });

    return uniqueResults;
  };


  const performSearchV2 = (items, searchTerm, searchColumns = ['first_name', 'last_name']) => {
    // if (searchTerm === '**ALL**') {
    //   return items;
    // }

    if (!searchTerm /*|| searchTerm.length < 2*/ || !searchColumns || !Array.isArray(searchColumns) || searchColumns.length === 0) {
      return [];
    }
    const lowercasedTerm = searchTerm.toLowerCase();
    const uniqueIds = new Set();
    const uniqueResults = [];

    items.forEach((item) => {
      if (item.letter_heading) return; // Skip if item has letter_heading

      // Check if any specified string property of the object includes whole words that start with the search term.
      const isMatch = searchColumns.some((columnName) => {
        const value = item[columnName];
        return typeof value === 'string' && value.toLowerCase().match(new RegExp(`\\b${lowercasedTerm}`));
      });

      if (isMatch && !uniqueIds.has(item.cellphone)) {
        uniqueIds.add(item.cellphone);
        uniqueResults.push(item);
      }
    });

    return uniqueResults;
  };
  const performSearchV1 = (items, searchTerm) => {
    if (!searchTerm || searchTerm.length < 2) {
      return [];
    }
    const lowercasedTerm = searchTerm.toLowerCase();
    const uniqueIds = new Set();
    const uniqueResults = [];

    items.forEach((item) => {
      if (item.letter_heading) return; // Skip if item has letter_heading

      // Check if any string property of the object includes the search term.
      // const isMatch = Object.values(item).some((value) =>
      //   typeof value === 'string' && value.toLowerCase().includes(lowercasedTerm)
      // );
      const isMatch = Object.values(item).some((value) =>
        typeof value === 'string' && value.toLowerCase().match(new RegExp(`\\b${lowercasedTerm}`))
      );



      if (isMatch && !uniqueIds.has(item.cellphone)) {
        uniqueIds.add(item.cellphone);
        uniqueResults.push(item);
      }
    });

    return uniqueResults;
  }


  const { partMessagesPool, isPartMessagesPoolCycling, error, updatePIDAndRestartPolling, cyclePartMessagePool } = useParticipantMessages()
  const useParticipantMessagesSingleton = { partMessagesPool, isPartMessagesPoolCycling, error, updatePIDAndRestartPolling, cyclePartMessagePool }


  const searchState = {
    searchType, setSearchType,
    searchTerm, setSearchTerm,
    isSearchTermDisabled, setIsSearchTermDisabled,
    searchResults, setSeachResults,
    sortConfig, setSortConfig,
    searchAPI, getGroupAPI,
    getRegionsList
  };

  const messagesState = {
    currentPerson, setCurrentPerson,
    getPersonApi,
    useParticipantMessagesSingleton,
  }

  const participantState = {
    participants,
    setParticipants, //update participants directly
    getParticipants, //update participants via API
    canUseCacheData, setCanUseCacheData
  }



  return (
    <div className="content">
      {/* <div className="tabs-component">
        <TabsComponent currentTab={currentTab} messagesState={messagesState} searchState={searchState} useLatestMessagesSingleton={useLatestMessagesSingleton} />
      </div> */}
      <div className="content-component">
        <Messages user={user} messagesState={messagesState} searchState={searchState} participantState={participantState} useLatestMessagesSingleton={useLatestMessagesSingleton} /> :


        {/* {currentTab === 'messages' ?
          <Messages user={user} messagesState={messagesState} searchState={searchState} participantState={participantState} useLatestMessagesSingleton={useLatestMessagesSingleton} /> :
          <Search user={user} messagesState={messagesState} searchState={searchState} />
        } */}
      </div>
    </div>
  );
};



function TabsComponent({ currentTab, messagesState, searchState, useLatestMessagesSingleton }) {
  console.log('<--- TABS --->')
  const { unreadCount, isLatestMessagesPoolCycling } = useLatestMessagesSingleton
  const { currentPerson } = messagesState;

  const UnreadMessagesBadge = ({ count, type }) => {
    // return
    if (type === 'search') return;
    if (parseInt(count) === 0) return;
    return (
      <div className="unread-messages-outer">
        <span className={`unread-messages-badge ${type}-badge  ${isLatestMessagesPoolCycling && type === 'messages' ? 'updated' : ''}`}>
          {count}
        </span>
      </div>
    );
  };

  const ResultText = () => {
    // if (currentTab !== 'search') return;
    if (searchState.searchTerm === '') return;
    if (searchState.searchResults.length === 0) return <span>0 results for "<b>{searchState.searchTerm}</b>"</span>;
    return <span className="search-results-help-text"><b>{searchState.searchResults.length}</b> result{searchState.searchResults.length === 1 ? '' : 's'} for "<b>{searchState.searchTerm}</b>"</span>;
  }

  const ResetIcon = () => {
    // return
    return <Link to={`/home/search/reset`}>
      <span className="search-results-help-text">
        <button className="btn btn-secondary tab-clear-button"><i className={`${receptionDeskVars.faicons.resetIcon3} `}></i>{/*`${receptionDeskVars.buttons.searchClear}`*/}</button>

        {/* <i className={`reset-icon ${receptionDeskVars.faicons.resetIcon} `}></i> */}
      </span>
    </Link>
  }

  return (
    <div className="tabs-component">
      <div className="">
        <ResetIcon />
      </div>
      <div className="tabs">
        {receptionDeskVars.mainTabs.map((tab, index) => (
          <Link key={index} to={`/home/${tab.link}/${currentPerson && tab.link === 'messages' ? currentPerson : ''}`} className={`tab ${currentTab === tab.link ? 'selected' : ''}`}>
            {tab.name}
            <UnreadMessagesBadge count={`${tab.link === 'messages'
              ? (unreadCount ? unreadCount : 0)
              : searchState.searchResults.length}`} type={tab.link} />
          </Link>
        ))}
      </div>
      <div className="tabs-extra-section">
        {/* <ResetIcon /> */}
        <ResultText />
      </div>
    </div>
  );
};








export default AppMainContent;
