import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import AppMainContent from './app/MainContent';


import "./app/2023css/App.scss";
import "./app/2023css/MainContent.scss";
import "./app/2023css/Messages.scss";
import "./app/2023css/Search.scss";
import "./app/2023css/PersonCard.scss";
import './app/2023css/User.scss';
import './app/2023css/HeroCard.scss'; // Import your CSS file for styling




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppMainContent />} />
        <Route path="/home/" element={<AppMainContent />} />
        <Route path="/home/:currentTab/" element={<AppMainContent />} />
        <Route path="/home/:currentTab/:personID/" element={<AppMainContent />} />


      </Routes>
    </BrowserRouter>


  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
