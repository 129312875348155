import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { DefaultizedPieValueType } from '@mui/x-charts';

const size = {
  height: 180,
};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 16,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export default function PieChartWithCenterLabel({ data, title, num }) {
  // Calculate the total sum of all values
  const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

  // Sort the data by value in descending order
  const sortedData = [...data].sort((a, b) => b.value - a.value);

  // Limit the number of labels to display
  const maxLabelsToShow = 7; // Adjust as needed
  const limitedData = sortedData.slice(0, maxLabelsToShow);

  // Calculate the sum of values for the limited data
  const limitedTotal = limitedData.map((item) => item.value).reduce((a, b) => a + b, 0);

  // Create a new data array with limited labels
  const newData = limitedData;

  // Only add the "Other" label/data if there are more items than the maximum
  if (sortedData.length > maxLabelsToShow) {
    const otherValue = TOTAL - limitedTotal;
    newData.push({ value: otherValue, label: 'Other' });
  }
  // const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
  };

  // innerRadius: 60, 
  //arcLabel: getArcLabel
  return (
    <PieChart series={[{ data: newData, innerRadius: 60 }]} slotProps={{
      legend: {
        labelStyle: {
          fontSize: 14,
          fill: 'black',

        },
        itemMarkWidth: 9,
        itemMarkHeight: 9,
        itemGap: 3,
        // width: 500,
        padding: -20,

      },
    }}
      {...size}>
      <PieCenterLabel>
        {num}
      </PieCenterLabel>
    </PieChart>

  );
  //{title}
}
